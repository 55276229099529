@import './hamburger.scss';

nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  height: clamp(65px, 16vw, 80px);
  background-color: $background-color-white;
  .navigation-contents {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    width: 95%;
    opacity: 0;
    transition: opacity 2s;
    margin: 0 auto;
    &.loaded {
      opacity: 1;
    }
    a {
      font-family: 'Open Sans', sans-serif;
      text-decoration: none;
      &, &:visited {
        color: #666666;
      }
    }
  }
  #logo {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $text-color-black;
    filter: brightness(0);
    font-weight: bold;
    font-size: clamp(27px, 5vw, 32px);
  }
  .navigation-links {
    width: clamp(500px, 80vw, 1250px);
    display: flex;
    justify-content: space-between;
    a {
      position: relative;
      transition: filter 0.3s linear;
      font-size: 16px;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $background-color-black;
        top: 85%;
        left: 0;
        pointer-events: none;
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s;
      }
      &.active, &:hover {
        filter: brightness(0);
        &::before {
          transform-origin: 0% 50%;
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
  #hamburger-container {
    @include hamburger;
    .navigation-contents {
      position: absolute;
      top: 0;
      height: 100vh;
      background-color: white;
      z-index: -1;
      right: -100vw;
      width: 100vw;
      visibility: hidden;
      opacity: 1 !important;
      transition: right .3s ease-in-out, visibility 0.3s ease-in-out;
      &.active {
        visibility: visible;
        right: 0;
      }
      .navigation-links {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: clamp(400px, 90vw, 500px);
        width: 100%;
        a {
          font-size: clamp(12px, 3vw, 16px);
        }
      }
    }
  }
}
