// Text Colors
$text-color-blue: #81B2D0;
$text-color-black: #525252;
$text-color-gray: #5A5A5A;
$text-color-white: #FFFFFF;
$text-color-gray-black: #282828;
$text-color-light-gray: #676767;

// Line colors
$line-color-blue: #79ABE5;

// Border Colors
$border-color-gray: #C9C9C9;
$border-color-blue: $line-color-blue;

// Icon colors
$icon-color-black: #3C3C3C;

// Background colors
$background-color-blue: #D6EBFF;
$background-color-gray: #878787;
$background-light-blue: #F7FAFF;
$background-color-white: #FFFFFF;
$background-color-black: $text-color-black;
$table-background-color-blue: #81B2D0;
