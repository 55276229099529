@mixin marquee {
  .marquee-wrapper {
    margin: clamp(30px, 5vw, 80px) 0;
    .marquee-container {
      .marquee {
        height: clamp(150px, 20vw, 450px);
        img {
          height: 100%;
        }
      }
      .overlay {
        display: none;
      }
    }
  }
}
