#trailer {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: clamp(15rem, 80vw, 56.9375rem);
    margin: 0 auto;
    h2 {
      color: $text-color-blue;
      font-family: 'Noto Sans JP', 'serif';
      font-size: clamp(2rem, 7vw, 3rem);
      font-weight: 600;
      margin-bottom: 1rem;
    }
    p {
      &, a {
        font-family: 'Noto Serif JP', 'serif';
        font-size: clamp(1rem, 3vw, 1.25rem);
        font-weight: 300;
      }
      margin: 0;
      color: $text-color-black;
      a {
        &, &:visited {
          color: $text-color-blue;
        }
        text-decoration-line: underline;
      }
    }
    .video-container {
      padding: 56.25% 0 0 0;
      position: relative;
      margin-top: clamp(2.75rem, 10vw, 4.75rem);
      iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        border: none;
      }
    }
  }
}
