.introduction, .name {
  font-family: 'Noto Serif JP', serif;
  display: flex;
  color: $text-color-black;
}
.introduction {
  justify-content: center;
  margin: 20px 0;
  .slash {
    width: 1px;
    background-color: $background-color-gray;
    transform: rotate(10deg);
  }
  .name {
    flex-direction: column;
    justify-content: center;
    p {
      margin: 0;
    }
    p:last-child {
      font-family: 'Noto Serif', serif;
      color: $text-color-blue;
    }
  }
  .role {
    p {
      margin: 0;
    }
  }
}

@mixin main-profile-introduction {
  .introduction {
    .role {
      p {
        font-size: clamp(25px, 6vw, 38px);
      }
      span {
        font-size: clamp(20px, 4vw, 30px);
      }
    }
    .slash {
      margin: 0 15px;
    }
    .name {
      p:first-child {
        font-size: clamp(15px, 4vw, 25px);
      }
      p:last-child {
        font-size: clamp(10px, 4vw, 20px);
      }
    }
  }
}

@mixin sub-profile-introduction {
  .introduction {
    .role {
      display: flex;
      align-items: center;
      p {
        font-size: clamp(16px, 4vw, 29px);
      }
      span {
        font-size: clamp(12px, 3vw, 22px);
      }
    }
    .slash {
      margin: 0 clamp(11px, 2vw, 15px);
    }
    .name {
      p:first-child {
        font-size: clamp(11px, 3vw, 17px);
      }
      p:last-child {
        font-size: clamp(8px, 3vw, 12px);
      }
    }
  }
}
