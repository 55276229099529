.icons-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  p {
    font-weight: 500;
    color: $text-color-gray;
  }
  i {
    font-size: clamp(18px, 3vw, 31px);
    color: $icon-color-black;
  }
}
