footer {
  color: $text-color-gray;
  text-align: center;
  font-family: 'Noto Serif JP', serif;
  .casts, .staffs {
    margin-bottom: 15px;
  }
  .main-participants, .support-participants {
    margin: 10px auto;
    span:not(:last-child) {
      margin-right: clamp(10px, 1vw, 30px);
    }
  }
  .main-participants, .theme-song {
    font-size: clamp(14px, 2vw, 20px);
    padding: 0 clamp(20px, 2vw, 30px);
  }
  .support-participants {
    font-weight: 100;
    font-size: clamp(10px, 1.7vw, 16px);
    padding: 0 clamp(20px, 1.7vw, 30px);
  }
  #footer-icons-container {
    width: clamp(100px, 16vw, 180px);
  }
  .copywright-information {
    color: $text-color-gray;
    font-weight: 100;
  }
  .theme-song {
    font-weight: 500;
  }
  .copywright-information {
    p {
      margin-bottom: 10px;
      font-weight: 100;
      color: $text-color-gray;
      font-size: clamp(8px, 1.3vw, 12px);
    }
  }
}
