@import './profile.scss';

#cast {
  #main-casts, #sub-casts {
    display: grid;
    grid-row-gap: 50px;
  }
  #main-casts {
    grid-template-columns: repeat(auto-fill, minmax(clamp(14rem, 52vw, 48rem), 1fr));
    margin: 50px auto;
    @include main-profile;
  }
  #sub-casts {
    grid-template-columns: repeat(auto-fill, minmax(clamp(10rem, 70vw, 34rem), 2fr));
    margin: 120px auto;
    @include sub-profile;
  }
}
