@import './marquee.scss';

#introduction {
  padding: 100px 0 !important;
  #intro-text {
    width: clamp(250px, 75vw, 1000px);
    margin: 30px auto 0 auto;
    font-family: "Noto Serif JP";
    font-weight: lighter;
    font-size: clamp(12px, 2vw, 16px);
    color: #676767;
  }
  @include marquee;
}
