.section-title {
  text-align: center;
  h2 {
    text-underline-offset: 10px;
    text-decoration: underline;
    width: auto;
    text-decoration-thickness: 1px;
    text-decoration-color: $border-color-gray;
    font-size: clamp(24px, 5vw, 35px);
    margin-top: 0;
    margin-bottom: 4px;
    padding-bottom: 4px;
    font-family: 'Noto Sans JP', sans-serif;
    color: $text-color-black;
  }
  span {
    font-family: 'Noto Sans', sans-serif;
    color: $text-color-blue;
    font-size: clamp(11px, 2vw, 16px);
  }
}