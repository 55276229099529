.video-responsive {
  padding-bottom: 40%;
  position: relative;
  height: 0;
  margin: 30px auto 0 auto;
  iframe {
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 85%;
    position: absolute;
    margin: 0 auto;
  }
}
