/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

@mixin hamburger {
  .hamburger {
    padding: 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    z-index: 1;
    &:hover {
      opacity: 0.7;
    }
    &.is-active:hover {
      opacity: 0.7;
    }
    &.is-active &-inner,
    &.is-active &-inner::before,
    &.is-active &-inner::after {
      background-color: $background-color-black;
    }
    &-box {
      width: 40px;
      height: 24px;
      display: inline-block;
      position: relative;
    }
    &-inner {
      display: block;
      top: 50%;
      margin-top: -2px;
    }
    &-inner, &-inner::before, &-inner::after {
      width: 36px;
      height: 2px;
      background-color: $background-color-black;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    &-inner::before, &-inner::after {
      content: "";
      display: block;
    }
    &-inner::before {
      top: -10px;
    }
    &-inner::after {
      bottom: -10px;
    }
    &--squeeze {
      & .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      & .hamburger-inner::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }
      & .hamburger-inner::after {
        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      &.is-active .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      &.is-active .hamburger-inner::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }
      &.is-active .hamburger-inner::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
