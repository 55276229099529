.timeline-container {
  margin: 80px auto;
  margin-bottom: 0;
  iframe:first-child {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 900px) {
  .timeline-container {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 467px) {
  .timeline-container {
    iframe {
      width: 100%;
    }
  }
}
