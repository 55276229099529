@import './common/fonts.scss';
@import './common/colors.scss';
@import './top.scss';
@import './comment.scss';
@import './footer.scss';
@import './theater_information.scss';
@import './getting_ready.scss';
@import './timelines.scss';
@import './cast.scss';
@import './staff.scss';
@import './introduction.scss';
@import './story.scss';
@import './common/section_title.scss';
@import './common/icons.scss';
@import './common/video_embed.scss';
@import './navbar.scss';
@import './loading_page.scss';
@import './spacial_thanks.scss';
@import './public-screenings.scss';
@import './trailer.scss';

body, html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

#root {
  height: 100%;
  background-image: url('https://res.cloudinary.com/wholemovie/image/upload/v1626530400/Whole_fc_422.00_01_23_18.Still004_jphjmt.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  .container {
    padding: 100px 30px;
    &:nth-child(even) {
      background-color: $background-color-white;
    }
    &:nth-child(odd) {
      background-color: $background-light-blue;
    }
  }
}

@media only screen and (max-width: 1200px) {
  #root {
    background-position-x: 70%;
    background-position-y: clamp(0px, 20vw, 60px);
  }
}
