#special-thanks {
  #thank-you-message {
    margin: 70px auto;
    width: 60%;
    font-size: clamp(12px, 2vw, 16px);
    p {
      font-family: 'Noto Serif JP', serif;
      font-weight: lighter;
      color: $text-color-gray-black;
    }
  }
  #supporters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(clamp(5rem, 43vw, 15rem), 1fr));
    grid-row-gap: 30px;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    .supporter {
      color: $text-color-light-gray;
      border-left: 1px solid $text-color-blue;
      p {
        font-family: 'Noto Serif JP', serif;
        margin: 0;
        font-size: clamp(12px, 2vw, 16px);
      }
    }
  }
}

