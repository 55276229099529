.loading-page {
  height: 100vh;
  width: 100vw;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #5D9BBE;
  opacity: 1;
  transition: all 0.3s ease-out;
  &.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .moon {
    background: #A8BECE;
    width: clamp(180px, 30vw, 256px);
    height: clamp(180px, 30vw, 256px);
    border-radius: 50%;
    position: relative;
    .moon-cover {
      background: #5D9BBE;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-radius: 50%;
      transition: width 0.4s;
    }
  }
  h1, p {
    color: $text-color-white;
    &:hover {
      cursor: default;
    }
  }
  h1 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 0;
    font-size: clamp(25px, 4vw, 32px);
  }
  p {
    font-family: "Noto Sans",sans-serif;
    filter: brightness(85%);
    margin: 0;
    font-size: clamp(12px, 2vw, 16px);
  }
}
