#top {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  h1, p, span {
    margin: 0;
    color: $text-color-white;
    &:hover {
      cursor: default;
    }
  }
  .top-title {
    position: absolute;
    bottom: 0;
    opacity: 0;
    left: clamp(30px, 4vw, 60px);
    transition: all 1s;
    &.loaded {
      bottom: 50px;
      opacity: 1;
    }
    h1, span {
      filter: brightness(95%);
    }
    h1 {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: bold;
      font-size: clamp(40px, 4vw, 70px);
      letter-spacing: 2px;
    }
    span {
      font-family: 'Noto Sans JP', sans-serif;
      font-size: clamp(12px, 1vw, 20px);
    }
  }
  p {
    writing-mode: vertical-rl;
    position: absolute;
    right: 0;
    opacity: 0;
    transition: all 1s;
    bottom: 50px;
    font-size: clamp(12px, 4vw, 25px);
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 100;
    letter-spacing: 12px;
    filter: brightness(85%);
    &.loaded {
      right: clamp(30px, 4vw, 100px);
      opacity: 1;
    }
  }
  img {
    position: absolute;
    left: clamp(30px, 4vw, 60px);
    top: -10px;
    width: 55vw;
    max-width: 400px;
    transition: all 1s;
    opacity: 0;
    &.loaded {
      top: clamp(30px, 25vw, 120px);
      opacity: 1;
    }
  }
}
