@import './profile.scss';

#staff {
  #main-staffs, #sub-staffs {
    display: grid;
  }
  #main-staffs {
    grid-row-gap: 50px;
  }
  #sub-staffs {
    grid-row-gap: clamp(10px, 2vw, 50px);
  }
  #main-staffs {
    grid-template-columns: repeat(auto-fill, minmax(clamp(14rem, 52vw, 48rem), 1fr));
    margin: 50px auto;
    @include main-profile;
  }
  #sub-staffs {
    grid-template-columns: repeat(auto-fill, minmax(clamp(18rem, 32vw, 23rem), 3fr));
    margin: clamp(10px, 10vw, 120px) auto;
    @include sub-profile-introduction
  }
}
