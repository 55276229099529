.getting-ready-container {
  text-align: center;
  color: antiquewhite;
  background-image: url('https://res.cloudinary.com/wholemovie/image/upload/v1626530400/Whole_fc_422.00_01_23_18.Still004_jphjmt.jpg');
  height: 100%;
  margin: 0;
  background-position: center;
  align-items: center;
  div {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);    
  }

  h1 {
    margin-top: 0;
    font-family: 'Noto Sans JP', sans-serif;
  }
  p {
    font-family: 'Noto Serif JP', serif;
  }
}