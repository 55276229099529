@import './common/buttons.scss';

#public-screenings {
  .main-contents {
    width: clamp(16.25rem, 80vw, 70.3125rem);
    margin: 3rem auto 0 auto;
    img {
      width: 100%;
    }
    .texts {
      margin-top: clamp(2rem, 5vw, 3rem);
      h2, p {
        margin: 0 0 1.5rem 0;
      }
      h2 {
        color: $text-color-blue;
        font-family: 'Noto Sans JP', serif;
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.09375rem;
        margin-bottom: 1rem;
      }
      p {
        color: #424242;
        font-family: 'Noto Serif JP', serif;
        font-size: clamp(0.75rem, 2vw, 1rem);
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.06875rem;
        line-height: 1.6rem;
      }
    }
    .links {
      display: flex;
      align-items: flex-start;
      gap: 3rem;
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        box-sizing: border-box;
      }
      .blue-button {
        @include cta-button;
      }
      .white-button {
        @include cta-button($background-color: $background-color-white, $color: $text-color-blue);
      }
    }
    small {
      color: #8C8C8C;
      font-family: 'Noto Serif JP', serif;
      font-size: clamp(0.5rem, 2vw, 0.75rem);
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.0375rem;
    }
  }
}

@media screen and (max-width: 500px) {
  #public-screenings {
    .links {
      flex-direction: column;
      gap: 1rem !important;
      a {
        width: 100%;
      }
    }
  }
}
