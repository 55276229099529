#comments {
  #comments-text-container {
    margin-top: 30px;
    font-size: clamp(10px, 3vw, 16px);
    .comment {
      padding-left: 15px;
      border-left: 2px solid $line-color-blue;
      max-width: 1200px;
      margin: 0 auto 50px auto;
      font-family: 'Noto Serif JP', serif;
      font-weight: lighter;
      .comment-text {
        p {
          color: $text-color-light-gray;
          margin: 0;
        }
        p:last-child {
          margin-bottom: 16px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .video-responsive {
    margin-top: clamp(50px, 12vw, 100px);
    iframe {
      width: 60%;
      height: 80%;
    }
  }
}
