
  .roadshow-table {
    font-family: 'Noto Serif JP', serif;
    color: $text-color-black;
    text-align: center;
    margin: 0 auto;
    margin: clamp(45px, 6vw, 70px) 0 clamp(45px, 6vw, 70px);
    &:last-child {
      margin-top: 0;
    }
    colgroup {
      col:first-child {
        width: 20%;
      }
      col:nth-child(2) {
        width: 20%;
      }
      col:last-child {
        width: 60%;
      }
    }
    p {
      margin: 30px 0px 20px;
      font-size: clamp(20px, 3vw, 25px);
    }
    table, th, td {
      border: 1px solid $table-background-color-blue;
    }
    table {
      border-collapse: collapse;
      margin: 0 auto;
      width: clamp(200px, 80vw, 800px);
      font-size: clamp(10px, 3vw, 16px);
      tr:first-child {
        th {
          color: $text-color-white;
        }
      }
      tr {
        th {
          background-color: $table-background-color-blue;
          }
        a {
            color: $text-color-blue;
          &:visited {
            color: $text-color-blue;
          }
        }
      }
      td {
        padding: 10px 1px;
      }
    }
  }
