%shared-button-style {
  border-radius: 0.5625rem;
  display: flex;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  font-family: 'Noto Serif JP', serif;
  font-size: clamp(0.75rem, 2vw, 1rem);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.05625rem;
  transition: 0.3s all;
}

@mixin cta-button($background-color: $text-color-blue, $color: $text-color-white) {
  background: $background-color;
  color: $color;
  border: 0.0625rem solid $text-color-blue;
  &:visited {
    color: $color
  }
  &:hover {
    background: $color;
    color: $background-color;
  }
  @extend %shared-button-style;
}
