// Sept 3rd, temporarily setting the css the same as introduction. Subject to change

#story {
  div:last-child {
    width: clamp(250px, 75vw, 1000px);
    margin: 30px auto 0 auto;
    font-family: "Noto Serif JP";
    font-weight: lighter;
    font-size: clamp(12px, 2vw, 16px);
    color: #676767;
  }
}
