@import './profile_introduction.scss';

.main-profile, .sub-profile {
  width: 100%;
  margin: 0 auto;
  color: $text-color-black;
  font-family: 'Noto Serif JP', serif;
  img {
    border-radius: 50%;
  }
  p {
    margin: 0;
  }
  .profile-pic {
    text-align: center;
  }
  .description {
    margin: 0 auto;
    font-weight: 100;
    color: $text-color-light-gray !important;
  }
}

@mixin main-profile {
  .main-profile {
    @include main-profile-introduction;
    img {
      width: clamp(120px, 40vw, 300px);
    }
    .description {
      width: clamp(250px, 45vw, 500px);
      font-size: clamp(12px, 3vw, 18px);
    }
  }
}

@mixin sub-profile {
  .sub-profile {
    @include sub-profile-introduction;
    img {
      width: clamp(70px, 35vw, 250px);
    }
    .description {
      width: clamp(250px, 45vw, 500px);
      font-size: clamp(9px, 3vw, 15px);
    }
  }
}
